import React from "react";
import { Link ,graphql} from "gatsby"
import { connect } from 'react-redux';

class MapIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            lang: this.props.lang,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            lang: nextProps.lang
        })
    }
    render() {
        const { edges: posts } = this.state.data.allMarkdownRemark;
        return (
            <div className="blog-posts">
                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }) => {
                        if (post.frontmatter.lang === this.state.lang) {
                            return (
                                <div className="blog-post-preview" key={post.id}>
                                    <h1>
                                        <Link to={post.frontmatter.path + this.props.location.search}>{post.frontmatter.title}</Link>
                                    </h1>
                                    <p>{post.excerpt}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapIndex);

export const pageQuery = graphql`
  query IndexQueryMapFarmer {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___title] },
                    filter: { frontmatter:  { type: { eq:"FARMER"}, module:{eq:"maps"}}}) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            path
            lang
            type
          }
        }
      }
    }
  }
`;